.card{
    background: darken($background, 3%);
    cursor: pointer;
    padding: $m-size;

    &:hover {
        .card__img {
            transform: scale(1.05);
        }

        .card__date {
            display: block;
        }
    }
}

.card__container {
    cursor: pointer;
    position: relative;
}

.card__img {
    height: auto;
    transition: $transition;
    width: 100%;
}

.card__date{
    background: rgba($white, 0.5);
    bottom: 7px;
    color: black;
    display: none;
    font-size: $font-size-small;
    left: 0;
    margin: 0;
    padding: 0 $m-size;
    position: absolute;
    transition: $transition;
    width: 100%;
}

.card__name{
    color: $blue;
    font-size: $font-size-large;
    text-align: center;
}


.card__description{
    color: lighten($blue, 10%);
    font-size: $font-size-small;
}

.card__btns {
    display: flex;
    justify-content: space-between;
    padding: 0 $l-size;
}

.card__btn {
    color: $white;
    font-size: $font-size-large;
    text-decoration: none;
    text-transform: uppercase;
    transition: $transition;

    &:hover {
        transform: scale(1.5);
    }
}

.card__btn--code:hover {
    color: $accent;
}

.card__btn--live:hover {
    color: $blue;
}