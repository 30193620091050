// Colors
$background: #2c2f33;
$blue: #6fa4b3;
$dark-blue: #23272a;
$accent: #22a7f0;
$white: #ECF0F1;

// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

// Spacing
$xs-size: 0.8rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

// Misc
$transition: 0.3s all ease;

// Mixin
@mixin sm-link($color, $font, $height, $left, $top, $width, $darken) {
    background: $color;
    font-size: $font;
    height: $height;
    left: $left;
    top: $top;
    width: $width;

    &:hover {
        background: darken($color, $darken);
    }

    @media (min-width: $desktop-breakpoint) {
        font-size: $font*1.5;
        height: $height*1.5;
        width: $width*1.5;
    }
}

$desktop-breakpoint: 45rem;
