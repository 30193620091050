.main__heading {
    color: $blue;
    font-size: 2.5rem;
    margin: $m-size 0 $m-size 0;
    font-weight: 700;
}

.hero {
    border-bottom: 1px solid $accent;
    margin-bottom: 25px;
    padding: $xl-size 0;
    position: relative;
    height: 50vh;
}

.hero__subcontainer {
    display: flex;
    justify-content: center;
    height: 100%;

    @media (min-width: $desktop-breakpoint) {
        justify-content: space-between;
    }
}

.hero__title {
    align-self: center;
    font-size: 2rem;
    justify-self: center;
    margin: 0;
    z-index: 5;

    span {
        color: lighten($accent, 10%);
    }

    @media (min-width: $desktop-breakpoint) {
        font-size: 2.5rem;
        justify-self: left;
    }
}

.hero__image {
    align-self: center;
    display: none;
    justify-self: right;
    height: auto;
    max-height: 100%;
    max-width: 260px;

    @media (min-width: $desktop-breakpoint) {
        display: block;
    }
}

.hero__link {
    background: $accent;
    bottom: -20.5px;
    color: $white;
    left: 50%;
    padding: $xs-size $xl-size;
    position: absolute;
    text-decoration: none;
    text-transform: uppercase;
    transform: translateX(-50%);
    transition: $transition;

    &:hover {
        background: darken($accent, 15%);
        border-radius: $s-size;
        transform: scale(1.02) translateX(-50%);
    }
}

.me-section {
    display: grid;
    grid-template-columns: 1fr;
    
    @media (min-width: $desktop-breakpoint) {
        grid-template-columns: 1fr 1fr;
    }
}

.me__container {
    min-height: 250px;
    padding: $m-size;
    position: relative;
    text-align: center;
}

.me__social-link {
    align-items: center;
    border-radius: 99px;
    cursor: pointer;
    display: grid;
    justify-items: center;
    position: absolute;
    transition: $transition;

    &:nth-of-type(1) {
        @include sm-link(#2C3E50, 5rem, 110px, 45%, 40%, 110px, 10%)
    }

    &:nth-of-type(2) {
        @include sm-link(#3498DB, 3rem, 70px, 25%, 20%, 70px, 30%)
    }

    &:nth-of-type(3) {
        @include sm-link(#e1306c, 2rem, 40px, 65%, 15%, 40px, 30%)
    }

    svg {
        align-items: center;
        color: $white;
        display: grid;
        justify-items: center;
        margin: 0;
        text-decoration: none;
    }

    &:hover {
        transform: scale(1.2)
    }
}

.featured-projects {
    border-top: 1px solid $accent;
    text-align: center;
}

.featured-projects__container {
    display: grid;
    grid-gap: $m-size;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: $desktop-breakpoint) {
        grid-template-columns: repeat(2, 1fr)
    }
}

.skills {
    border-top: 1px solid $accent;
    display: grid;
    grid-template-columns: 1fr;
    margin: $m-size 0;
    text-align: center;

    @media (min-width: $desktop-breakpoint) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.skills__container {
    margin-top: $m-size;
}

.skills__list {
    list-style: none;
    padding: 0;
}