.contact {
    text-align: center;
    margin-bottom: $xl-size;
    
    @media (min-width: $desktop-breakpoint) {
        margin-bottom: 0;
    }
}

.contact__heading {
    color: $blue;
}

.contact__subheading {
    color: $white;
}

.contact__form {
    input, textarea {
        background: lighten($background, 60%);
        border: none;
        color: darken($white, 50%);
        display: block;
        margin: $l-size 0;
        outline: none;
        padding: $s-size;
        transition: $transition;
        width: 100%;

        &:focus {
            border-radius: $m-size;
        }

        &::placeholder {
            color: darken($white, 50%);
        }
    }
}

.contact__buttons {
    display: flex;
    justify-content: flex-end;
}

.btn {
    border: none;
    font-size: 2.5rem;
    outline: none;
    padding: $xs-size $m-size;
    transition: $transition;

    &:hover {
        border-radius: $s-size;

        svg {
            transition: $transition;
            transform: scale(1.2);
        }
    }
}

.btn--location {
    background: #1E6F48;

    &:hover {
        background: darken(#1E6F48, 10%)
    }
}

.btn--submit {
    background: $accent;

    &:hover {
        background: darken($accent, 10%)
    }
}