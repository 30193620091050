nav {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 99;
}

.navbar {
    display: flex;
    justify-content: space-between;
    padding: $m-size 0;
    position: relative;
}

.navbar-border {
    border-bottom: 1px solid rgba($accent, 0.5);
}

.navbar__outer {
    background: $dark-blue;
    position: relative;
}

.navbar__icon {
    display: block;
    cursor: pointer;
    font-size: 2.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @media (min-width: $desktop-breakpoint) {
        display: none;
    }
}

.navbar__icon-on {
    color: $accent;
}

.navbar__menu {
    animation: slide-down 0.4s ease;
    background: darken($dark-blue, 5%);
    display: grid;
    justify-items: center;
    left: 0;
    position: absolute;
    top: 100%;
    transition: 0.4s ease;
    width: 100%;
    z-index: 99;

    & > a {
        display: block;
    }
}

.navbar__links {
    display: none;

    @media (min-width: $desktop-breakpoint) {
        display: block;
    }
}

.navbar__link {
    color: $white;
    margin: $s-size 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    
    &:hover {
        color: $accent;
    }

    &::after {
        content: none;
    }

    @media (min-width: $desktop-breakpoint) {
        margin: 0 $s-size;

        &::after {
            border-bottom: 2px $accent solid;
            bottom: -5px;
            content: '';
            left: 0;
            position: absolute;
            transition: 0.2s all ease-in-out;
            width: 0;
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }
    }
}

.navbar__link-active {
    color: $accent;
    font-weight: bold;

    &::after {
        content: none;
    }

    @media (min-width: $desktop-breakpoint) {
        &::after {
        border-bottom: 2px $accent solid;
        bottom: -5px;
        content: '';
        left: 0;
        position: absolute;
        transition: 0.2s all ease-in-out;
        width: 100%;
    }
    }
}

.navbar__logo {
    height: 30px;
    transition: $transition;
    width: 30px;

    & a {
        display: inline-block;
    }

    & img {
        display: block;
        height: 100%;
        width: 100%;
    }

    &:hover {
        transform: scale(1.2);
    }
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-98px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}