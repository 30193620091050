.footer {
    background: $dark-blue;
    border-top: 1px solid $accent;
    color: $white;
    bottom: 0;
    left: 0;
    padding: $m-size 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.footer__scrollup {
    margin: $s-size 0;
    
    svg {
        transition: $transition;
        
        &:hover {
            transform: scale(1.4);
        }
    }

    &:hover {
        color: $accent;
        cursor: pointer;
    }
}

.footer_tm {
    margin: $s-size 0;
}

.footer_social-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 320px;
    padding: $s-size 0;
}

.footer_social-icon {
    margin: 0;
    transition: $transition;

    a {
        color: $white;
        font-size: 2.2rem;
        text-decoration: none;

        &:hover {
            color: $accent;
        }
    }

    &:hover {
        transform: scale(1.2);
    }
}