* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background-color: $background;
  color: $white;
  font-family: 'Roboto', sans-serif;
  font-size: $m-size;
  line-height: 1.6;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.right-border {
  border-right: none;

  @media (min-width: $desktop-breakpoint) {
    border-right: 1px solid $accent;
  }
}

.bottom-border {
  border-bottom: 1px solid $accent;

  @media (min-width: $desktop-breakpoint) {
    border-bottom: none;
  }
}

#app {
  width: 100%;
  height: 100%;
}

#app-wrapper {
  min-height: 100vh;
  padding-bottom: 171px;
  position: relative;
}